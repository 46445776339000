import React, { useEffect, useRef, useState } from "react";
import EditorJS from "@editorjs/editorjs";
import axios from "axios";

import { useDispatch } from "react-redux";

import Header from "@editorjs/header";
import ImageTool from "@editorjs/image";
import RawTool from "@editorjs/raw";
import Quote from "@editorjs/quote";
import NestedList from "@editorjs/nested-list";
import LinkTool from "@editorjs/link";
import Checklist from "@editorjs/checklist";
import Table from "@editorjs/table";
import Marker from "@editorjs/marker";
import InlineCode from "@editorjs/inline-code";
import AttachesTool from "@editorjs/attaches";
import debounce from "lodash.debounce";

import { editActiveTask, editTask } from "../../redux/editor/editor.slice";
import { useSelector } from "react-redux";
import { apiUrl } from "../../api/ApiService";

import "./editor.scss";

const token = localStorage.getItem("token");
const fetchImageByUrl = async (url) => {
  try {
    const response = await axios.get(
      url,
      {
        responseType: "blob",
      },
      {
        headers: {
          Authorization: `Bearer ` + token,
        },
      }
    );

    if (response.status === 200) {
      const blob = new Blob([response.data]);
      const imageUrl = URL.createObjectURL(blob);

      return imageUrl;
    } else {
      throw new Error("Error fetching image by URL");
    }
  } catch (error) {
    console.error("Error fetching image by URL:", error);
    return null;
  }
};

const TaskEditor = ({ activeTask, pointerValue }) => {
  const [value, setValue] = useState({
    blocks: activeTask.blocks,
  });
  const dispatch = useDispatch();
  const module = useSelector((state) => state.editor.module);

  const token = localStorage.getItem("token");
  const editorRef = useRef(null);

  useEffect(() => {
    if (pointerValue === null) return;

    const theme = module?.themes.find(
      (th) => Number(th.id) === Number(activeTask.theme_id)
    );
    const task = theme?.tasks.find(
      (item) => Number(item.id) === Number(activeTask.id)
    );
    dispatch(
      editTask({
        ...task,
        experience: pointerValue,
        title: value?.blocks[0]?.data?.text,
        content: JSON.stringify(value),
      })
    );
  }, [value]);
  useEffect(() => {
    const editor = new EditorJS({
      holder: activeTask.id,
      placeholder: "Введите заголовок",
      tools: {
        header: {
          class: Header,
          inlineToolbar: true,
        },
        list: {
          class: NestedList,
          inlineToolbar: true,
          config: {
            defaultStyle: "unordered",
          },
        },
        // linkTool: {
        //   class: LinkTool,
        //   config: {
        //     endpoint: 'http://localhost:8008/fetchUrl',
        //   },
        // },
        // checklist: {
        //   class: Checklist,
        //   inlineToolbar: true,
        // },
        table: {
          class: Table,
          inlineToolbar: true,
          config: {
            rows: 2,
            cols: 3,
          },
        },
        image: {
          class: ImageTool,
          config: {
            uploader: {
              uploadByFile: async (file) => {
                try {
                  if (!file) {
                    console.error("File is undefined");
                    return {
                      success: 0,
                      file: {
                        url: "",
                      },
                    };
                  }

                  const formData = new FormData();
                  formData.append("file", file);
                  formData.append("task_id", activeTask.id);

                  const response = await axios.post(
                    `${apiUrl}/api/v2/tasks/uploadFile`,
                    formData,
                    {
                      headers: {
                        Authorization: `Bearer ` + token,
                      },
                    }
                  );

                  if (response.status === 200) {
                    // .replace(/\kod06\b/g, 'code06')
                    const imagePath = response.data.file_path;
                    console.log(imagePath);

                    return {
                      success: 1,
                      file: {
                        url: imagePath,
                      },
                    };
                  } else {
                    throw new Error("Error uploading file");
                  }
                } catch (error) {
                  console.error("Error uploading file:", error);
                  return {
                    success: 0,
                    file: {
                      url: "",
                    },
                  };
                }
              },
              uploadByUrl: async (url) => {
                try {
                  const imageUrl = await fetchImageByUrl(url);

                  if (imageUrl) {
                    return {
                      success: 1,
                      file: {
                        url: imageUrl,
                      },
                    };
                  } else {
                    throw new Error("Error fetching image by URL");
                  }
                } catch (error) {
                  console.error("Error fetching image by URL:", error);
                  return {
                    success: 0,
                    file: {
                      url: "",
                    },
                  };
                }
              },
            },
          },
        },
        // attaches: {
        //   class: AttachesTool,
        //   config: {
        //     endpoint: "http://localhost:8008/uploadFile",
        //   },
        // },
        Marker: {
          class: Marker,
          shortcut: "CMD+SHIFT+M",
        },
        inlineCode: {
          class: InlineCode,
          shortcut: "CMD+SHIFT+M",
        },
        // raw: RawTool,
        // quote: Quote,
      },
      i18n: {
        /**
         * @type {I18nDictionary}
         */
        messages: {
          ui: {
            blockTunes: {
              toggler: {
                "Click to tune": "Нажмите, чтобы настроить",
                "or drag to move": "или перетащите",
              },
            },
            inlineToolbar: {
              converter: {
                "Convert to": "Конвертировать в",
              },
            },
            toolbar: {
              toolbox: {
                Add: "Добавить",
              },
            },
          },

          toolNames: {
            Text: "Параграф",
            Heading: "Заголовок",
            List: "Список",
            Warning: "Примечание",
            Checklist: "Чеклист",
            Quote: "Цитата",
            Code: "Код",
            Delimiter: "Разделитель",
            "Raw HTML": "HTML-фрагмент",
            Table: "Таблица",
            Link: "Ссылка",
            Marker: "Маркер",
            Bold: "Полужирный",
            Italic: "Курсив",
            InlineCode: "Моноширинный",
            Image: "Изображение",
            Attachment: "Файл",
          },

          tools: {
            warning: {
              Title: "Название",
              Message: "Сообщение",
            },

            link: {
              "Add a link": "Вставьте ссылку",
            },

            stub: {
              "The block can not be displayed correctly.":
                "Блок не может быть отображен",
            },
          },

          blockTunes: {
            delete: {
              Delete: "Удалить",
            },
            moveUp: {
              "Move up": "Переместить вверх",
            },
            moveDown: {
              "Move down": "Переместить вниз",
            },
          },
        },
      },

      onChange: (api) => {
        api.saver.save().then((data) => {
          setValue({
            ...activeTask,
            blocks: data.blocks,
          });
          dispatch(
            editActiveTask({
              data,
              theme_id: activeTask.theme_id,
              task_id: activeTask.id,
              experience: pointerValue,
            })
          );
        });
      },
      data: {
        blocks: activeTask.blocks,
      },
      autofocus: true,
    });

    editorRef.current = editor;

    return () => {
      editor.isReady.then(() => editor.destroy());
    };
  }, [activeTask.id]);

  return (
    <div id="editorjs">
      <div className="editor" id={activeTask.id}></div>
    </div>
  );
};
export default TaskEditor;
