import React from "react";
import "./Authorization.scss";

import Input from "../../UI/Input/Input";
import Button from "../../UI/Button/Button";

import eye from "../../assets/img/icons/eye 2.svg";
import close from "../../assets/img/icons/close.svg";
import password from "../../assets/img/icons/lock.svg";
import login from "../../assets/img/icons/at-sign.svg";
import logoCB from "../../assets/img/icons/logo CB.svg";
import oneC from "../../assets/img/icons/1c.svg";
import hideEye from "../../assets/img/icons/hideEye.svg";
import gosuslugi from "../../assets/img/госуслуги logo.png";
import errorCircle from "../.././assets/img/errorCircle.svg";
import authorizationImg from "../../assets/img/Programming-pana 1.svg";

import { Link, useNavigate } from "react-router-dom";

import Modal from "../../components/Modal/Modal";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";

import axios from "axios";
import { Auth } from "../../context/context";
import { apiUrl } from "../../api/ApiService";
import { Alert } from "antd";

const Authorization = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const [loginData, setLoginData] = React.useState({
    email: "",
    password: "",
  });

  const [error, setError] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState("");

  let [showPassword, setShowPassword] = React.useState(false);
  const handleEmailChange = (e) => {
    setLoginData({ ...loginData, email: e.target.value });
    setError(null);
  };

  const handlePasswordChange = (e) => {
    setLoginData({ ...loginData, password: e.target.value });
    setError(null);
  };

  let modal = React.useRef(null);
  let { setIsAuth } = React.useContext(Auth);

  function showModal(e) {
    e.preventDefault();
    modal.current.style.top = "40px";

    setTimeout(() => {
      document.querySelector(".authorization_img").style.visibility = "hidden";
    }, 200);
  }

  function hideModal() {
    modal.current.style.top = "150%";
    document.querySelector(".authorization_img").style.visibility = "visible";
  }

  function formSubmitHandler(e) {
    e.preventDefault();
  }

  async function logIn() {
    try {
      let userData = {
        email: loginData.email,
        password: loginData.password,
      };

      const { data } = await axios.post(
        `${apiUrl}/api/v2/auth/login`,
        userData,
        {
          headers: {
            Authorization: `Bearer ` + token,
          },
        }
      );

      localStorage.setItem("token", data.access_token);
      localStorage.setItem("role", data.role);
      setIsAuth(true);
    } catch (error) {
      navigate("/");
      setError("error");
      setErrorMessage("Неверные данные");
    }
  }

  function showPasswordFunction() {
    setShowPassword(!showPassword);
  }

  return (
    <div className="authorization">
      <div
        style={{
          position: "absolute",
          bottom: "20px",
          left: "20px",
          zIndex: 9999,
        }}
      >
        {error && (
          <Alert
            message="Ошибка"
            description={errorMessage}
            type="error"
            showIcon
            onClose={() => setError(null)}
            style={{ width: "100%", width: "300px" }}
          />
        )}
      </div>
      <img src={authorizationImg} className="authorization_img" alt="img" />

      <div className="authorization-block">
        <img src={oneC} className="oneC" alt="oneC" />
        <h2>Вход в Аккаунт</h2>
        <form
          onSubmit={(e) => formSubmitHandler(e)}
          className="authorization-form"
        >
          <label htmlFor="login">
            <img className="icon login" src={login} alt="login" />
            <Input
              onChange={handleEmailChange}
              id="login"
              type="text"
              placeholder="email"
              className={error ? "error-input" : ""}
            />

            {error && (
              <img className="icon eye" src={errorCircle} alt="errorCircle" />
            )}
          </label>

          <label htmlFor="password">
            <img className="icon password" src={password} alt="password" />
            <Input
              onChange={handlePasswordChange}
              type={showPassword ? "text" : "password"}
              placeholder="password"
              className={error ? "error-input" : ""}
            />
            {error && <span className="error-text">Неверные данные</span>}

            {error ? (
              <img className="icon eye" src={errorCircle} alt="errorCircle" />
            ) : (
              <img
                onClick={showPasswordFunction}
                className="icon eye"
                src={showPassword ? hideEye : eye}
                alt="eye"
              />
            )}
          </label>

          <div className="remember">
            <Link to="/forgot-password" className="forgot-password">
              Забыли пароль?
            </Link>
          </div>
          <Button onClick={logIn} buttonColor="black">
            Войти
          </Button>

          <a
            href="https://futurecode.p.rnds.pro/auth/authorize?client_id=SMg-polcb0Ic39oAHhs9xDCtYY1dhSHaZMkYLyIGtE0&response_type=code&scope=openid%20fullname%20birthdate%20gender%20mobile%20snils%20inn%20id_doc%20email&redirect_uri=https://crm.kod06.ru/auth/gosuslugi&provider=esia_oauth&state=1a73f168-4485-11ed-b878-0242ac120002"
            className="signIn_gosuslugi"
          >
            <img src={gosuslugi} alt="ГосУслуги" />
          </a>
        </form>

        <div className="privacy-policy">
          <p className="copyright">
            © 2021-2023 ООО «AIS». Все права защищены.
          </p>
          <p className="site-rules">
            <a onClick={(e) => showModal(e)} href="/">
              Правила сайта
            </a>
            <span> | </span>
            <a onClick={(e) => showModal(e)} href="/">
              Политика конфиденциальности
            </a>
          </p>
        </div>
      </div>

      <Modal modal={modal}>
        <div className="modal-body">
          <img src={logoCB} alt="logoCB" />
          <div onClick={hideModal} className="close">
            <img src={close} alt="close" />
          </div>

          <PrivacyPolicy />
        </div>
      </Modal>
    </div>
  );
};

export default Authorization;
