import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Input, Button, Space, Modal, message } from "antd";
import axios from "axios";
import "./OutUserGroup.scss";

import Avatar from "../../components/Avatar/Avatar";
import ReactSelect from "../.././UI/ReactSelect/ReactSelect";

import { apiUrl } from "../../api/ApiService";
import arrow from "../.././assets/img/icons/arrow.svg";
import plusWhite from "../../assets/img/icons/plusWhite.svg";
import power from "../../assets/img/icons/power.svg";
import settings from "../../assets/img/icons/settings.svg";
import cart from "../../assets/img/icons/cart.svg";
import IconSearch from "../../assets/img/icons/iconSearch";

const { confirm } = Modal;

const UserAdmin = () => {
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const [teacher, setTeacher] = React.useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCount, setSelectedCount] = useState(0);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState([]);

  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [isAddToGroupModalVisible, setIsAddToGroupModalVisible] =
    useState(false);
  const [userOptions, setUserOptions] = useState([]);
  const [selectedUsersForGroup, setSelectedUsersForGroup] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const closeAddToGroupModal = () => {
    setIsAddToGroupModalVisible(false);
  };

  const handleToggleDisabled = async (userId) => {
    setFilteredUsers((prevListUser) => {
      return {
        ...prevListUser,

        users: prevListUser.users.map((user) =>
          user.id === userId ? { ...user, isDisabled: !user.isDisabled } : user
        ),
      };
    });

    try {
      await axios.post(
        `${apiUrl}/api/v2/users/activate`,
        { users: [userId] },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      message.success("Пользователь успешно деактивирован");
    } catch (error) {
      console.log("Error deactivating user", error);
      message.error("Не удалось деактивировать пользователя");
    }
  };

  const handleToggleEnabled = async (userId) => {
    setFilteredUsers((prevListUser) => {
      return {
        ...prevListUser,

        users: prevListUser.users.map((user) =>
          user.id === userId ? { ...user, isDisabled: !user.isDisabled } : user
        ),
      };
    });
    try {
      await axios.post(
        `${apiUrl}/api/v2/users/activate`,
        { users: [userId] },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      message.success("Пользователь успешно активирован ");
    } catch (error) {
      console.log("Error activating user", error);
      message.error("Пользователя не получилось деактивировать ");
    }
  };

  const handleDeleteSelectedUsers = async () => {
    confirm({
      title: "Подтвердите действие",
      content: "Вы уверены, что хотите удалить выбранных пользователей?",
      onOk: async () => {
        try {
          const deletePromises = selectedUserIds.map(async (userId) => {
            await axios.delete(`${apiUrl}/api/v2/groups/kickOut`, {
              headers: {
                Authorization: "Bearer " + token,
              },
              data: {
                group_id: id,
                users: [userId],
              },
            });
          });

          await Promise.all(deletePromises);

          const newUserList = {
            ...filteredUsers,
            users: filteredUsers?.users?.filter(
              (user) => !selectedUserIds.includes(user?.id)
            ),
          };

          setFilteredUsers(newUserList);
          setSelectedUserIds([]);
          setSelectedCount(0);

          message.success("Пользователи успешно удалены.");
        } catch (error) {
          console.log("Error deleting users", error);
        }
      },
    });
  };

  const handleDeleteUser = async (userId) => {
    confirm({
      title: "Подтвердите действие",
      content: "Вы уверены, что хотите удалить  пользователя?",
      onOk: async () => {
        try {
          await axios.delete(`${apiUrl}/api/v2/groups/kickOut`, {
            headers: {
              Authorization: "Bearer " + token,
            },
            data: {
              group_id: id,
              users: [userId],
            },
          });
          const newUserList = {
            ...filteredUsers,
            users: filteredUsers?.users?.filter((us) => us?.id !== userId),
          };

          setFilteredUsers(newUserList);
          setSelectedCount(0);

          message.success("Пользователь успешно удален.");
        } catch (error) {
          console.log("Error deleting user", error);
        }
      },
    });
  };

  const handleAddToGroup = async () => {
    try {
      const dataToAddToGroup = {
        group_id: id,
        users: selectedUserIds,
      };

      await axios.post(`${apiUrl}/api/v2/groups/bindUsers`, dataToAddToGroup, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      setIsAddToGroupModalVisible(false);
      setSelectedUsersForGroup([]);

      message.success("Пользователи успешно добавлены в группу.");
      const { data } = await axios.get(`${apiUrl}/api/v2/groups/${id}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      const filteredData = {
        ...data,
      };

      setFilteredUsers(filteredData);
    } catch (error) {
      console.log("Ошибка при добавлении пользователей в группу", error);
    }
  };

  const cancelAction = () => {
    setDeleteModalVisible(false);
  };
  const handleUpdateTeacher = async (newTeacherId) => {
    console.log(newTeacherId);

    try {
      const response = await axios.put(
        `${apiUrl}/api/v2/groups/updateTeacher`,
        {
          group_id: id,
          teacher_id: newTeacherId,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      message.success("Учитель успешно обновлен.");
    } catch (error) {
      console.log("Error updating teacher", error);
      message.error("Не удалось обновить учителя.");
    }
  };

  React.useEffect(() => {
    axios
      .get(`${apiUrl}/api/v2/users?role=teacher`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        const users = response.data.data.users.map((user) => ({
          name: user.name,
          value: user.id,
        }));
        setTeacher(users);
      })
      .catch((error) => {
        console.error("Error fetching users", error);
      });
  }, []);
  React.useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await axios.get(`${apiUrl}/api/v2/groups/${id}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });

        const filteredData = {
          ...data,
          users: data.users.filter((user) =>
            user.name.toLowerCase().includes(searchQuery.toLowerCase())
          ),
        };

        setFilteredUsers(filteredData);
      } catch (error) {
        console.log("Error fetching users", error);
      }
    };
    fetchUsers();
  }, [id, searchQuery]);

  React.useEffect(() => {
    axios
      .get(`${apiUrl}/api/v2/users?role=student`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        const users = response.data.data.users.map((user) => ({
          label: user.name,
          value: user.id,
          user: user,
        }));
        setUserOptions(users);
      })
      .catch((error) => {
        console.error("Error fetching users", error);
      });
  }, [id]);

  return (
    <>
      <div className="table">
        <div className="block">
          <div className="button_group">
            <div className="buttons">
              <div className="button-group-admin">
                <div className="group-teacher">
                  <span className="teacher">
                    <p>Преподаватель:</p>
                    <ReactSelect
                      value={selectedTeacher}
                      options={teacher}
                      changeFunction={(option) => {
                        setSelectedTeacher(option);
                        handleUpdateTeacher(option.id);
                      }}
                      className="name-teacher"
                    >
                      {filteredUsers.teacher?.name ? (
                        <Avatar width1>
                          {filteredUsers.teacher.name.charAt(0)}
                        </Avatar>
                      ) : (
                        "Нет учителя"
                      )}
                      <p style={{ marginRight: "20px" }}>
                        {selectedTeacher?.label || filteredUsers.teacher?.name}{" "}
                      </p>
                    </ReactSelect>
                  </span>

                  <Input
                    style={{
                      background: "#f0f0f0",
                      border: "0px",
                      width: "280px",
                    }}
                    placeholder="Быстрый поиск"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    prefix={<IconSearch />}
                  />
                </div>

                <Space wrap>
                  <Button
                    onClick={() => {
                      setIsAddToGroupModalVisible(true);
                    }}
                    type="primary"
                    style={{
                      background: "#2898EC",
                      color: "white",
                      padding: "22px",
                    }}
                  >
                    <img src={plusWhite} alt="Add Icon" />
                    <p className="font"> Добавить пользователя</p>
                  </Button>
                </Space>
              </div>
            </div>
          </div>

          <div className=".table-groups ">
            <table className="table-group-user">
              <tr className="tr">
                <th className="th" style={{ width: "44px" }}>
                  <input
                    type="checkbox"
                    checked={selectAllChecked}
                    onChange={() => {
                      setSelectAllChecked(!selectAllChecked);
                      if (!selectAllChecked) {
                        const allUserIds = filteredUsers?.users?.map(
                          (user) => user.id
                        );
                        setSelectedUserIds(allUserIds || []);
                        setSelectedCount(allUserIds?.length || 0);
                      } else {
                        setSelectedUserIds([]);
                        setSelectedCount(0);
                      }
                    }}
                  />
                </th>
                <th className="th">
                  Студент
                  <span className="th-group">
                    {filteredUsers.users
                      ? `${filteredUsers.users.length} `
                      : "0 "}
                  </span>
                </th>
                <th className="th">Школа</th>
                <th className="th">Номер</th>
                <th className="th">Электронный адрес</th>
                <th className="th">Действия</th>
              </tr>

              <div className="lists-users">
                {filteredUsers?.users?.map((obj) => {
                  return (
                    <tr
                      className={`tr user-change ${
                        obj.isDisabled ? "disabled" : ""
                      } ${obj.deleted_at ? "disabled" : ""}`}
                      key={obj.id}
                    >
                      <td className="td td-checkbox" style={{ width: "44px" }}>
                        <input
                          type="checkbox"
                          checked={selectedUserIds?.includes(obj.id)}
                          onChange={(e) => {
                            const userId = obj.id;
                            if (e.target.checked) {
                              setSelectedCount((prevCount) => prevCount + 1);
                              setSelectedUserIds((prevIds) => [
                                ...prevIds,
                                userId,
                              ]);
                            } else {
                              setSelectedCount((prevCount) =>
                                Math.max(prevCount - 1, 0)
                              );
                              setSelectedUserIds((prevIds) =>
                                prevIds.filter((id) => id !== userId)
                              );
                            }
                          }}
                        />
                      </td>
                      <td className="td td-name">
                        <Avatar width1>{obj.name.slice(0, 1)}</Avatar>
                        <p style={{ marginLeft: "12px", width: "140px" }}>
                          {obj.name.slice(0, 14)}...
                        </p>
                      </td>
                      <td className="td td-school">{obj.school_id}</td>
                      <td className="td td-phone_number">{obj.phone_number}</td>
                      <td className="td td-email">
                        {obj.email.slice(0, 19)}...
                      </td>
                      <td className="td td-action">
                        {obj.isDisabled ? (
                          <img
                            src={power}
                            alt="Power Icon"
                            onClick={() => handleToggleEnabled(obj.id)}
                          />
                        ) : (
                          <img
                            src={power}
                            alt="Power Icon"
                            onClick={() => handleToggleDisabled(obj.id)}
                          />
                        )}
                        <Link
                          style={{ height: "24px" }}
                          to={`/settings/${obj.id}`}
                        >
                          <img src={settings} alt="Settings Icon" />
                        </Link>
                        <img
                          src={cart}
                          alt="Cart Icon"
                          onClick={() => handleDeleteUser(obj.id)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </div>
            </table>
          </div>
          <hr className="hr-info" />
          <div className="setting-down">
            <p>Выделено: {selectedCount}</p>
            <div className="button-groups">
              <button
                style={{ color: "red" }}
                onClick={handleDeleteSelectedUsers}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M2.5 5H17.5"
                    stroke="#D80914"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15.8332 5V16.6667C15.8332 17.5 14.9998 18.3333 14.1665 18.3333H5.83317C4.99984 18.3333 4.1665 17.5 4.1665 16.6667V5"
                    stroke="#D80914"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.6665 4.99984V3.33317C6.6665 2.49984 7.49984 1.6665 8.33317 1.6665H11.6665C12.4998 1.6665 13.3332 2.49984 13.3332 3.33317V4.99984"
                    stroke="#D80914"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.3335 9.1665V14.1665"
                    stroke="#D80914"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.6665 9.1665V14.1665"
                    stroke="#D80914"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Удалить из группы
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Добавление в группу"
        visible={isAddToGroupModalVisible}
        onCancel={closeAddToGroupModal}
        onOk={handleAddToGroup}
        okText="Добавить в группу"
        cancelText="Отмена"
      >
        <ReactSelect
          options={userOptions}
          value={selectedUsersForGroup}
          changeFunction={(selectedUser) => {
            setSelectedUsersForGroup(selectedUser);
            setSelectedUserIds([...selectedUserIds, selectedUser.id]);
          }}
          isMulti
          placeholder="Выберите пользователей"
          closeMenuOnSelect={false}
        >
          <div>{selectedUsersForGroup.label || "Выберите пользователя"}</div>
        </ReactSelect>
      </Modal>

      <Modal
        title="Подтвердите действие"
        visible={deleteModalVisible}
        onOk={handleDeleteUser}
        onCancel={cancelAction}
      >
        <p>Вы уверены, что хотите удалить этого пользователя?</p>
      </Modal>
    </>
  );
};

export default UserAdmin;
