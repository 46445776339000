import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { apiUrl } from "../../api/ApiService";

export const getGroupModules = createAsyncThunk(
  "get/getGroupModules",
  async ({ id }, thunkAPI) => {
    if (!id) return;
    const token = localStorage.getItem("token");

    try {
      const { data } = await axios.get(`${apiUrl}/api/v2/groupModules/${id}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (data.error) {
        return thunkAPI.rejectWithValue(data.error);
      }
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getStudentModule = createAsyncThunk(
  "get/getStudentModule",
  async ({ id }, thunkAPI) => {
    const token = localStorage.getItem("token");

    try {
      const { data } = await axios.get(
        `${apiUrl}/api/v2/groupModules/groupModule/${id}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (data.error) {
        return thunkAPI.rejectWithValue(data.error);
      }
      console.log(data, "<-----====== getStudentModule");
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const tabCardSlice = createSlice({
  name: "tabCard",
  initialState: {
    progress: [],
    module: null,
    loading: false,
    error: null,
  },
  reducers: {
    changeProgress(state, { payload }) {
      state.progress = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGroupModules.fulfilled, (state, { payload }) => {
      state.progress = payload;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(getStudentModule.fulfilled, (state, { payload }) => {
      console.log(payload, " payload");
      state.module = payload;
    });
    // builder.addCase(getGroupModules.pending, (state≠) => {
    //   state.error = null
    //   state.loading = true
    // })
    // builder.addCase(getGroupModules.fulfilled, (state, { payload }) => {
    //   state.error = payload
    //   state.loading = false
    // })
  },
});

export const { changeProgress } = tabCardSlice.actions;

export default tabCardSlice.reducer;
