import React, { useEffect, useState } from 'react';
import AccordionBody from './AccordionBody/AccordionBody';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getStudentTask,
  getThemesCourses,
  responceTask,
} from '../../redux/themeCourse/themesCourses';
import { convertDataToJSX } from '../../components/TaskEditor/configuration.utils';
import { complitedStudentTask } from '../../redux/themeCourse/themesCourses';
import { TextField } from '@mui/material';
import './SiteMap.scss';
import { editTasks } from '../../redux/courses/siteMap/site-map.slice';

const SiteMap = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { studentTask, themes, taskResponseStudent } = useSelector((state) => state.themesCourses);
  const moduleStudentTheme = useSelector((state) => state.tabCard.module);

  const { tasks } = useSelector((state) => state.jobsOutput);
  const [idComplitedTask, setIdComplitedTask] = useState(null);

  const textFieldDisabled = studentTask?.status !== null && true;

  console.log(studentTask?.content, 'studentTask');
  

  let leftColumn = React.useRef(null);

  let completedTheme = 0;

  function addClass(obj) {
    if (obj.status === 'completed') {
      completedTheme++;
      return `module-count_span module-count_span passed`;
    } else if (obj.status === 'current') {
      return `module-count_span module-count_span current`;
    } else {
      return `module-count_span module-count_span`;
    }
  }

  const handleStudentTask = (id_theme, id_task, theme_status) => {
    setIdComplitedTask({ id_theme: id_theme, id_task: id_task, theme_status: theme_status });
    dispatch(getStudentTask({ id: id_task, theme_id: id_theme }));
  };
  const handleTaskCompited = (status) => {
    const { id_theme, id_task } = idComplitedTask;
    const newTasks = tasks?.map((ts) => {
      if (Number(ts.id) === Number(id_task)) {
        return { ...ts, status };
      }

      return ts;
    });

    dispatch(editTasks(newTasks));
    dispatch(complitedStudentTask({ id_theme, id_task, answer_content: taskResponseStudent }));
  };
  const handleChangeTaskResponse = (e) => {
    dispatch(responceTask(e.target.value));
  };

  useEffect(() => {
    dispatch(getThemesCourses({ id }));
  }, []);

  return (
    <div className="site-map">
      <div className="site-map_columns">
        <div ref={leftColumn} className="left-column">
          <p className="left-column_title">
            Модуль {moduleStudentTheme?.order} : {moduleStudentTheme?.title}
          </p>

          <div className="tab-card_progress">
            <div className="tab-card_progress-diagram">
              {themes &&
                themes.map((obj) => {
                  return <span key={obj.id} className={addClass(obj)}></span>;
                })}
            </div>
            <p className="tab-card_progress-description">
              <span className="module-count">
                {completedTheme}/{themes && themes.length} Тема
              </span>

              <span className="prosent">
                <span>{themes && Math.floor((completedTheme / themes.length) * 100)}</span>%
              </span>
            </p>
          </div>
          <div className="accordions">
            {themes &&
              themes.map((obj) => {
                return <AccordionBody key={obj.id} obj={obj} onTaskClick={handleStudentTask} />;
              })}
          </div>
        </div>
        {studentTask && (
          <div className="rigth-column">
            <div className="courses-head">
              {studentTask?.content === null && <h1>Пустое задание, выберите другое....</h1>}
              {studentTask && studentTask.content?.blocks.map((block) => convertDataToJSX(block))}
            </div>

            <div className="input-task-result">
              <TextField
                disabled={textFieldDisabled}
                value={taskResponseStudent}
                onChange={handleChangeTaskResponse}
                className="text-field"
                id="outlined-multiline-static"
                multiline
                rows={4}
                placeholder="Ответ"
              />
            </div>
            {idComplitedTask?.theme_status === 'completed' ? (
              <button disabled onClick={() => handleTaskCompited('check')} className="button-check">
                Тема завершена
              </button>
            ) : studentTask.status === 'completed' ? (
              <button className="button-complited" style={{backgroundColor: '#78CE4E'}}>
                Задание выполнено
              </button>
            ) : studentTask.status === null ? (
               <button onClick={() => taskResponseStudent && handleTaskCompited('check')} className="button-complited" disabled={!taskResponseStudent}>
                 Отправить задание
               </button>
            ) : (
              <button onClick={() => handleTaskCompited(null)} className="button-check">
                Отмена
              </button>
            )}
          </div>
        )}
        {/* <OutTask currentTaskId={currentTaskId} /> */}
      </div>
    </div>
  );
};

export default SiteMap;
