import React, { useState } from "react";

import Input from "../../UI/Input/Input";
import Button from "../../UI/Button/Button";

import { useLocation, useNavigate } from "react-router-dom";

import close from "../../assets/img/icons/close.svg";
import logoCB from "../../assets/img/icons/logo CB.svg";
import authorizationImg from "../../assets/img/Programming-pana 1.svg";
import oneC from "../../assets/img/icons/1c.svg";

import Modal from "../../components/Modal/Modal";

import axios from "axios";
import { apiUrl } from "../../api/ApiService";
import { Alert } from "antd";

const ResetPassword = () => {
  const [passwordData, setPasswordData] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();
  let [showPassword, setShowPassword] = useState(false);

  let modal = React.useRef(null);
  const location = useLocation();

  const extractTokenFromPath = () => {
    const pathSegments = location.pathname.split("/");
    const lastSegment = pathSegments[pathSegments.length - 1];

    return lastSegment;
  };

  function showModal(e) {
    e.preventDefault();
    modal.current.style.top = "40px";

    setTimeout(() => {
      document.querySelector(".authorization_img").style.visibility = "hidden";
    }, 200);
  }

  function hideModal() {
    modal.current.style.top = "150%";
    document.querySelector(".authorization_img").style.visibility = "visible";
  }

  async function resetPassword() {
    try {
      if (passwordData.newPassword !== passwordData.confirmPassword) {
        setError("error");
        setErrorMessage("Пароли не совпадают");
        return;
      }

      const tokens = extractTokenFromPath();

      const userData = {
        password: passwordData.newPassword,
        password_confirmation: passwordData.confirmPassword,
        token: tokens,
      };

      const { data } = await axios.post(
        `${apiUrl}/api/v2/resetPassword/reset`,
        userData
      );
      navigate("/");
    } catch (error) {
      setError("error");
      setErrorMessage("Неверные данные");
    }
  }

  return (
    <div className="authorization">
      <div
        style={{
          position: "absolute",
          bottom: "20px",
          left: "20px",
          zIndex: 9999,
        }}
      >
        {error && (
          <Alert
            message="Ошибка"
            description={errorMessage}
            type="error"
            showIcon
            onClose={() => setError(null)}
            style={{ width: "100%", width: "300px" }}
          />
        )}
      </div>
      <img src={authorizationImg} className="authorization_img" alt="img" />

      <div className="authorization-block">
        <img src={oneC} className="oneC" alt="oneC" />
        <h2>Восстановление пароля</h2>
        <form
          onSubmit={(e) => e.preventDefault()}
          className="authorization-form"
        >
          <label htmlFor="newPassword">
            <Input
              onChange={(e) =>
                setPasswordData({
                  ...passwordData,
                  newPassword: e.target.value,
                })
              }
              id="newPassword"
              type={showPassword ? "text" : "password"}
              placeholder="Новый пароль"
            />
          </label>

          <label htmlFor="confirmPassword">
            <Input
              onChange={(e) =>
                setPasswordData({
                  ...passwordData,
                  confirmPassword: e.target.value,
                })
              }
              id="confirmPassword"
              type={showPassword ? "text" : "password"}
              placeholder="Подтверждение пароля"
            />
          </label>

          <Button onClick={resetPassword} buttonColor="black">
            Сохранить
          </Button>
        </form>

        <div className="privacy-policy">
          <p className="copyright">
            © 2021-2023 ООО «AIS». Все права защищены.
          </p>
          <p className="site-rules">
            <a onClick={(e) => showModal(e)} href="/">
              Правила сайта
            </a>
            <span> | </span>
            <a onClick={(e) => showModal(e)} href="/">
              Политика конфиденциальности
            </a>
          </p>
        </div>
      </div>

      <Modal modal={modal}>
        <div className="modal-body">
          <img src={logoCB} alt="logoCB" />
          <div onClick={hideModal} className="close">
            <img src={close} alt="close" />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ResetPassword;
