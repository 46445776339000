import React, { useState } from "react";
import "./ForgotPassword.scss";
import unlock from "../../assets/img/icons/unlock.svg";
import authorizationImg from "../../assets/img/Programming-pana 1.svg";
import login from "../../assets/img/icons/at-sign.svg";
import Input from "../../UI/Input/Input";
import Modal from "../../components/Modal/Modal";
import logoCB from "../../assets/img/icons/logo CB.svg";
import close from "../../assets/img/icons/close.svg";
import PrivacyPolicy from "../Authorization/PrivacyPolicy/PrivacyPolicy";
import { Link } from "react-router-dom";

import { apiUrl } from "../../api/ApiService";
import axios from "axios";
import oneC from "../../assets/img/icons/1c.svg";

const ForgotPassword = () => {
  const token = localStorage.getItem("token");

  let modal = React.useRef(null);
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  function showModal(e) {
    e.preventDefault();
    modal.current.style.top = "40px";
  }

  function hideModal() {
    modal.current.style.top = "150%";
  }

  async function sendResetPasswordRequest() {
    if (!email) {
      setErrorMessage("Пожалуйста, заполните поле email.");
      return;
    }

    try {
      const { data } = await axios.post(
        `${apiUrl}/api/v2/resetPassword/forgot`,
        { email },
        {
          headers: {
            Authorization: `Bearer ` + token,
          },
        }
      );
      console.log("Ответ от сервера:", data);
    } catch (error) {
      console.error("Ошибка при отправке запроса:", error);
    }
  }

  return (
    <div className="authorization">
      <img src={authorizationImg} className="authorization_img" alt="img" />

      <div className="forgot-password-block">
        <img src={oneC} className="oneC" alt="oneC" />
        <div className="unlock-img">
          <img src={unlock} alt="unlock" />
        </div>

        <h2>Забыли пароль?</h2>
        <p className="forgot-password_description">
          Мы вышлем инструкцию по восстановлению
        </p>
        <form className="forgot-password_form">
          <label htmlFor="login">
            <img className="icon login" src={login} alt="login" />
            <Input
              id="login"
              type="text"
              placeholder="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setErrorMessage("");
              }}
            />
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </label>
          <Link
            to={`/sent/${email}`}
            className="forgot-password_link restore-password"
            onClick={() => {
              sendResetPasswordRequest();
            }}
          >
            Восстановить пароль
          </Link>
          <Link to="/" className="forgot-password_link back">
            Назад
          </Link>
        </form>

        <div className="privacy-policy">
          <p className="copyright">
            © 2021-2023 ООО «AIS». Все права защищены.
          </p>
          <p className="site-rules">
            <a onClick={(e) => showModal(e)} href="/">
              Правила сайта
            </a>
            <span> | </span>
            <a onClick={(e) => showModal(e)} href="/">
              Политика конфиденциальности
            </a>
          </p>
        </div>
      </div>

      <Modal modal={modal}>
        <div className="modal-body">
          <img src={logoCB} alt="logoCB" />
          <div onClick={hideModal} className="close">
            <img src={close} alt="close" />
          </div>

          <PrivacyPolicy />
        </div>
      </Modal>
    </div>
  );
};

export default ForgotPassword;
