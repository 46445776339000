import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from "../api/ApiService";

export const fetchCurrentUser = createAsyncThunk(
  "rating/fetchCurrentUser",
  async (token) => {
    const { data } = await axios.get(`${apiUrl}/api/v2/rating/user`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return data;
  }
);

export const fetchUserList = createAsyncThunk(
  "rating/fetchUserList",
  async ({ urlText, token }) => {
    const { data } = await axios.get(`${apiUrl}/api/v2/${urlText}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return data;
  }
);

const initialState = {
  userList: [],
  urlText: "rating",
  ratingType: "general",
  currentUser: {},
  ratingLoader: true,
};

const ratingSlice = createSlice({
  name: "rating",
  initialState,
  reducers: {
    groupUserList(state, action) {
      const data = action.payload;
      if (data.length) {
        axios
          .get(`${apiUrl}/api/v2/rating/${data[0].id}`, {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          })
          .then((response) => {
            state.userList = response.data;
            state.ratingLoader = false;
          });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        state.currentUser = action.payload;
        state.ratingLoader = false;
      })
      .addCase(fetchUserList.fulfilled, (state, action) => {
        if (state.urlText === "groups") {
          state.groupUserList(action.payload);
        } else {
          state.userList = action.payload;
          state.ratingLoader = false;
        }
      });
  },
});

export const { changeColor, groupUserList } = ratingSlice.actions;

export default ratingSlice.reducer;
