import React, { useState, useEffect } from "react";
import "./GeneralMagazineAttendance.scss";
import axios from "axios";
import { apiUrl } from "../../api/ApiService";
import CustomSelector from "../../UI/CustomSelector/CustomSelector";
import ReactSelect from "../../UI/ReactSelect/ReactSelect";
import { Button, message } from "antd";

const GeneralMagazineAttendance = () => {
  const [response, setResponse] = useState({});
  const [selectGroup, setSelectGroup] = useState({ id: null });
  const [selectTheme, setSelectTheme] = useState({});
  const [selectModule, setSelectModule] = useState({});
  const [magazineGroups, setMagazineGroups] = useState([]);
  const [magazineThemes, setMagazineThemes] = useState([]);
  const [magazineModules, setMagazineModules] = useState([]);

  const [magazineType, setMagazineType] = useState({
    id: 2,
    label: "Проверка заданий",
  });

  const token = localStorage.getItem("token");

  const magazines = [
    { id: 1, name: "Посещаемость", active: true },
    { id: 2, name: "Проверка заданий", active: false },
  ];
  const handleCustomSelectorChange = (studentId, taskId, newStatus) => {
    console.log(studentId, taskId, newStatus);
    const attendanceUrl = `${apiUrl}/api/v2/attendance`;
    const gradebookUrl = `${apiUrl}/api/v2/gradebook`;
    if (magazineType.id === 1) {
      sendStatusToServer(studentId, taskId, newStatus, attendanceUrl);
    } else if (magazineType.id === 2) {
      sendStatusToServer(studentId, taskId, newStatus, gradebookUrl);
    }
  };

  const sendStatusToServer = async (studentId, taskId, task, urlBase) => {
    try {
      const requestMethod = magazineType.id === 1 ? "put" : "post";

      const requestData = {
        group_theme_id: selectTheme.id || response.themes?.[0]?.id,
        status: task,
        [magazineType.id === 1 ? "group_lesson_id" : "task_id"]: taskId,
        user_id: studentId,
      };

      await axios[requestMethod](`${urlBase}`, requestData, {
        headers: {
          Authorization: `Bearer ` + token,
        },
      });
    } catch (error) {
      console.log("Error sending status to server", error);
    }
  };

  useEffect(() => {
    const handleSubmit = async () => {
      try {
        setResponse({});
        const urlBase =
          magazineType.id === 1
            ? `${apiUrl}/api/v2/attendance`
            : `${apiUrl}/api/v2/gradebook`;

        let queryString = "";

        if (selectTheme.id) {
          queryString = `/${
            selectGroup.id || response.groups.groups?.[0]?.id
          }/${selectModule.id || response.group_modules?.[0]?.id}/${
            selectTheme.id || response.themes?.[0]?.id
          }`;
        } else if (selectModule.id) {
          queryString = `/${
            selectGroup.id || response.groups.groups?.[0]?.id
          }/${selectModule.id}`;
        } else if (selectGroup.id) {
          queryString = `/${selectGroup.id}`;
        }

        const urlWithQuery = ` ${urlBase}${queryString}`;

        const { data } = await axios.get(urlWithQuery, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        setMagazineGroups(data.groups || []);
        setMagazineModules(data.group_modules || []);
        setMagazineThemes(data.themes || []);
        setResponse(data);
      } catch (error) {
        console.log("Ошибка", error);
      }
    };
    handleSubmit();
  }, [magazineType, selectGroup, selectModule, selectTheme]);

  const completeTheme = async () => {
    if (selectTheme.id || response.themes?.[0]?.group_id) {
      try {
        const url = `${apiUrl}/api/v2/groupThemes?_method=put`;
        await axios.post(
          url,
          {
            group_theme_id:
              selectTheme.id || (response.themes && response.themes?.[0]?.id),
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );

        message.success("Тема успешно завершена");
      } catch (error) {
        if (error.response && error.response.status === 400) {
          message.success("Тема уже завершена");
        } else {
          message.error("Произошла ошибка при завершении темы");
        }
      }
    }
  };

  return (
    <div className="general-magazine-attendance">
      <div className="table-container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
            justifyContent: "space-between",
          }}
        >
          <div className="general-magazine-attendance_information-tabs">
            <ReactSelect options={magazines} changeFunction={setMagazineType}>
              Журнал: <span>{magazineType?.label || magazines[1].name}</span>
            </ReactSelect>
            <ReactSelect
              options={magazineGroups.groups || []}
              changeFunction={(option) => setSelectGroup(option)}
              value={selectGroup}
            >
              Группа:
              <span>
                {selectGroup?.label ||
                  (response.groups && response.groups.groups?.[0]?.name) ||
                  ""}
              </span>
            </ReactSelect>
            <ReactSelect
              options={magazineModules}
              changeFunction={(option) => setSelectModule(option)}
              value={selectModule}
            >
              Модуль:
              <span>
                {selectModule?.order ||
                  (response.group_modules &&
                    response.group_modules?.[0]?.order) ||
                  ""}
              </span>
            </ReactSelect>
            <ReactSelect
              options={magazineThemes}
              changeFunction={(option) => setSelectTheme(option)}
              value={selectTheme}
            >
              Тема:
              <span>
                {selectTheme?.label ||
                  (response.themes && response.themes?.[0]?.title) ||
                  ""}
              </span>
            </ReactSelect>
          </div>
          {magazineType.id === 2 && (
            <div>
              <Button
                className="custom-button"
                onClick={completeTheme}
                disabled={selectTheme.status === "completed"}
              >
                Завершить тему
              </Button>
            </div>
          )}
        </div>

        <div className="general-magazine-attendance_board ">
          <table className="general-magazine-attendance_board-table">
            <thead>
              <tr>
                <th className="last-name">ФИО</th>
                {magazineType.id === 1
                  ? Array.isArray(response.lessons) &&
                    response.lessons.map((lesson, i) => (
                      <th className="tasks" key={i}>
                        <span
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <p>{lesson.number}</p>
                        </span>
                      </th>
                    ))
                  : Array.isArray(response.tasks) &&
                    response.tasks.map((task, i) => (
                      <th className="tasks" key={i}>
                        <p>{task.title}</p>
                      </th>
                    ))}
              </tr>
            </thead>
            <tbody>
              {magazineType.id === 1 ? (
                Array.isArray(response.students) &&
                response.students.length > 0 ? (
                  response.students.map((student, studentId) => (
                    <tr key={studentId}>
                      <td className="users-name">
                        {student.name.length > 25
                          ? `${student.name}`
                          : student.name}
                      </td>
                      {Array.isArray(student.lessons) &&
                      student.lessons.length > 0
                        ? student.lessons.map((lesson) => (
                            <td
                              className="select-tasks"
                              key={lesson.group_lesson_id}
                            >
                              <CustomSelector
                                status={lesson ? lesson.status : ""}
                                taskId={lesson.group_lesson_id}
                                userId={student.id}
                                onChange={(newStatus) =>
                                  handleCustomSelectorChange(
                                    student.id,
                                    lesson.group_lesson_id,
                                    newStatus
                                  )
                                }
                              />
                            </td>
                          ))
                        : null}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={
                        response.lessons ? response.lessons.length + 1 : 2
                      }
                    >
                      No students found.
                    </td>
                  </tr>
                )
              ) : Array.isArray(response.student_tasks) &&
                response.student_tasks.length > 0 ? (
                response.student_tasks.map((student, studentId) => (
                  <tr key={studentId}>
                    <td className="users-name">
                      {student.name.length > 25
                        ? `${student.name}`
                        : student.name}
                    </td>
                    {Array.isArray(student.tasks) && student.tasks.length > 0
                      ? student.tasks.map((item, taskId) => (
                          <td className="select-tasks" key={item.task_id}>
                            <CustomSelector
                              magazineType={magazineType}
                              themeId={
                                selectTheme.id ||
                                (response.themes && response.themes?.[0]?.id)
                              }
                              taskId={student.tasks[taskId].task_id}
                              userId={student.id}
                              status={item.status}
                              onChange={(newStatus) =>
                                handleCustomSelectorChange(
                                  student.id,
                                  student.tasks[taskId].task_id,
                                  newStatus
                                )
                              }
                            />
                          </td>
                        ))
                      : null}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={response.tasks ? response.tasks.length + 1 : 2}>
                    Студенты не найдены.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default GeneralMagazineAttendance;
