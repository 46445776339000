import React from "react";
import { authRoutes, routesUsers, routesAdmins, routesTeachers } from "./index";
import { Route, Routes } from "react-router-dom";
import { Auth } from "../context/context";
import Header from "../pages/Header/Header";

const AppRoutes = () => {
  const { value } = React.useContext(Auth);

  const role = localStorage.getItem("role");

  // Список всех роутов
  const mapRoutes = {
    student: routesUsers,
    admin: routesAdmins,
    teacher: routesTeachers,
  };

  // Вытаскиваем роуты по роли пользователя
  const routes = mapRoutes[role];

  // Если пользователь не авторизован
  if (!value) {
    return (
      <Routes>
        {authRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Routes>
    );
  }

  // Если пользователь авторизован, возвращать приватные роуты
  return (
    <>
      <Header />
      <Routes>
        {routes?.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Routes>
    </>
  );
};

export default AppRoutes;
