import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apiUrl } from "../../api/ApiService";
import axios from "axios";

import { getCurrentToken } from "../../functions/function";
import { setDescription } from "../updateModuleEditor";

const token = localStorage.getItem("token");

export const getModule = createAsyncThunk(
  "get/getModules",
  async ({ id }, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${apiUrl}/api/v2/modules/module/${id}`,
        {
          headers: {
            Authorization: `Bearer` + token,
          },
        }
      );
      if (data.error) {
        return thunkAPI.rejectWithValue(data.error);
      }
      const themes = data.themes.map((th) => ({ ...th, isEdit: false }));

      thunkAPI.dispatch(getTasks({ id: themes[0]?.id }));

      if (themes.length) {
        thunkAPI.dispatch(openThemeAccordion([themes[0].id]));
      }

      thunkAPI.dispatch(setDescription(data.title));

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const appendTheme = createAsyncThunk(
  "post/appendTheme",
  async ({ title, module_id }, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${apiUrl}/api/v2/themes`,
        {
          title: title,
          module_id: module_id,
          lesson_quantity: 1,
        },
        {
          headers: {
            Authorization: `Bearer` + token,
          },
        }
      );

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateTheme = createAsyncThunk(
  "patch/updateTheme",
  async ({ id, title, order }, thunkAPI) => {
    try {
      const { data } = await axios.put(
        `${apiUrl}/api/v2/themes`,
        {
          id,
          title,
          order,
        },
        {
          headers: {
            Authorization: `Bearer` + token,
          },
        }
      );
      if (data.error) {
        return thunkAPI.rejectWithValue(data.error);
      }
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const deleteTheme = createAsyncThunk(
  "post/deleteTheme",
  async ({ id }, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${apiUrl}/api/v2/themes?_method=delete`,
        {
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer` + token,
          },
        }
      );
      if (data.error) {
        return thunkAPI.rejectWithValue(data.error);
      }

      return id;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const appendTask = createAsyncThunk(
  "post/appendTask",
  async ({ newTask, experience }, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${apiUrl}/api/v2/tasks`,
        {
          content: JSON.stringify(newTask.content),
          theme_id: newTask.theme_id,
          title: newTask.blocks[0].data.text,
          experience,
        },
        {
          headers: {
            Authorization: `Bearer` + token,
          },
        }
      );
      if (data.error) {
        return thunkAPI.rejectWithValue(data.error);
      }
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const editTask = createAsyncThunk(
  "put/editTask",
  async ({ id, order, title, content, experience }, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${apiUrl}/api/v2/tasks?_method=put`,
        {
          id,
          order,
          title,
          content,
          experience,
        },
        {
          headers: {
            Authorization: `Bearer` + token,
          },
        }
      );

      if (data.error) {
        return thunkAPI.rejectWithValue(data.error);
      }

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTasks = createAsyncThunk(
  "get/getTasks",
  async ({ id }, thunkAPI) => {
    try {
      const { data } = await axios.get(`${apiUrl}/api/v2/tasks/${id}`, {
        headers: {
          Authorization: `Bearer` + token,
        },
      });

      if (data.error) {
        return thunkAPI.rejectWithValue(data.error);
      }
      thunkAPI.dispatch(getTask({ id: data[0].id }));

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTask = createAsyncThunk(
  "get/getTask",
  async ({ id }, thunkAPI) => {
    try {
      const { data } = await axios.get(`${apiUrl}/api/v2/tasks/task/${id}`, {
        headers: {
          Authorization: `Bearer` + token,
        },
      });

      if (data.error) {
        return thunkAPI.rejectWithValue(data.error);
      }

      const { content, ...otherData } = data;

      if (content) {
        return {
          ...otherData,
          id: String(otherData.id),
          blocks: JSON.parse(content).blocks,
        };
      }
      return {
        ...otherData,
        id: String(otherData.id),
        blocks: [{ type: "header", data: { text: otherData.title, level: 1 } }],
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteTask = createAsyncThunk(
  "get/deleteTask",
  async (params, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${apiUrl}/api/v2/tasks?_method=delete`,
        {
          id: params.task_id,
        },
        {
          headers: {
            Authorization: `Bearer` + token,
          },
        }
      );
      if (data.error) {
        return thunkAPI.rejectWithValue(data.error);
      }
      return params;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const editorSlice = createSlice({
  name: "editor",
  initialState: {
    openedAccordionThemeIds: [],
    themes: [],
    tasks: [],
    module: {},
    isThemesLoading: false,
    isTasksEditLoading: "",
    activeTask: null,
    inputActive: false,
    addThemeInputValue: "",
    editError: null,
  },
  reducers: {
    themeInputActive(state, { payload }) {
      state.inputActive = payload;
    },
    modulesThemes(state, { payload }) {
      state.module = payload;
    },

    openThemeAccordion(state, { payload }) {
      state.openedAccordionThemeIds = payload;
    },
    setAddThemeInputValue(state, { payload }) {
      state.addThemeInputValue = payload;
    },

    editTheme(state, { payload }) {
      state.module.themes = payload;
    },
    addTask(state, { payload }) {
      state.tasks.push(payload);
    },
    editActiveTask(state, { payload }) {
      const { data, theme_id, task_id } = payload;
      if (payload === undefined) return;
      const title = data?.blocks[0]?.data?.text;
      const themes = state.module.themes.find((th) => th.id === theme_id);
      const task = themes?.tasks?.map((item) =>
        Number(item.id) === Number(task_id) ? { ...item, title } : item
      );
      state.module.themes.map((th) => {
        if (th.id === theme_id) {
          th.tasks = task;
        }
        return th;
      });
    },
    setActiveTask(state, { payload }) {
      state.activeTask = payload;
    },
  },
  extraReducers: (builder) => {
    //GETMODULE

    builder.addCase(getModule.pending, (state) => {
      state.isThemesLoading = true;
    });
    builder.addCase(getModule.fulfilled, (state, { payload }) => {
      state.module = payload;
      state.isThemesLoading = false;
    });
    builder.addCase(getModule.rejected, (state, { payload }) => {
      state.error = payload;
      state.isThemesLoading = false;
    });

    //DELETETHEME
    builder.addCase(deleteTheme.fulfilled, (state, { payload }) => {
      const themes = state.module.themes.filter((th) => th.id !== payload);

      state.module = { ...state.module, themes };
    });
    //ADDTHEME

    builder.addCase(appendTheme.fulfilled, (state, { payload }) => {
      if (payload === undefined) return;
      state.module.themes.push(payload);
      state.addThemeInputValue = "";
    });
    builder.addCase(appendTheme.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(updateTheme.fulfilled, (state, { payload }) => {
      state.error = payload;
    });
    //GETTASKS
    builder.addCase(getTasks.fulfilled, (state, { payload }) => {
      state.tasks = [...state.tasks, ...payload];
    });

    builder.addCase(getTasks.rejected, (state, { payload }) => {
      state.error = payload;
    });

    //APPENDTASK
    builder.addCase(appendTask.fulfilled, (state, { payload }) => {
      const { content, ...task } = payload;
      state.module.themes.map((th) => {
        if (th.id === payload.theme_id) {
          th.tasks.push(task);
        }
        return th;
      });
    });
    builder.addCase(appendTask.pending, (state, { payload }) => {});
    builder.addCase(appendTask.rejected, (state, { payload }) => {
      state.error = payload;
    });
    //GETTASK
    builder.addCase(getTask.fulfilled, (state, { payload }) => {
      if (!state.activeTask) {
        state.activeTask = payload;
      }
    });
    builder.addCase(getTask.pending, (state, { payload }) => {
      state.activeTask = payload;
    });
    builder.addCase(getTask.rejected, (state, { payload }) => {
      state.activeTask = payload;
      state.error = payload;
    });
    //DELETETASK
    builder.addCase(deleteTask.fulfilled, (state, { payload }) => {
      const { theme_id, task_id } = payload;
      const theme = state.module.themes.find((th) => th.id === theme_id);
      const tasks = theme.tasks.filter((ts) => ts.id !== task_id);
      state.module.themes.map((th) => {
        if (th.id === theme_id) {
          th.tasks = tasks;
        }
        return th;
      });
    });
    builder.addCase(deleteTask.rejected, (state, { payload }) => {
      state.error = payload;
    });
    //EDIT TASK
    builder.addCase(editTask.fulfilled, (state, { payload }) => {
      state.isTasksEditLoading = "succes";
      state.module.themes = state.module?.themes?.map((theme) => {
        if (theme.id === payload.theme_id) {
          return {
            ...theme,
            tasks: theme.tasks.map((item) => {
              if (item.id === payload.id) {
                return payload;
              }
              return item;
            }),
          };
        }
        return theme;
      });
    });
    builder.addCase(editTask.pending, (state, { payload }) => {
      state.isTasksEditLoading = "loading";
    });
    builder.addCase(editTask.rejected, (state, { payload }) => {
      state.isTasksEditLoading = "error";
    });
  },
});

export const {
  openThemeAccordion,
  editTheme,
  setAddThemeInputValue,
  addTheme,
  addTask,
  modulesThemes,
  editActiveTask,
  setActiveTask,
  themeInputActive,
} = editorSlice.actions;

export default editorSlice.reducer;
