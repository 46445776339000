import React, { useRef } from "react";
import Avatar from "../Avatar/Avatar";
import Notification from "../Notification/Notification";
import { userService } from "../../api/user";
import bellDot from "../../assets/img/icons/bell-dot.svg";
import notifications from "../../assets/img/icons/notifications.svg";
import hackcoins from "../../assets/img/icons/hackcoins.svg";
import refresh from "../../assets/img/refresh-ccw.svg";
import notNotification from "../.././assets/img/notNotifications.svg";
import CardModal from "../CardModal/CardModal";
import "./UserInfo.scss";

import { apiUrl } from "../../api/ApiService";
import axios from "../../core/axios";

import { Fragment } from "react";
import { message } from "antd";

const UserInfo = () => {
  const [currentUser, setCurrentUser] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isCartModal, setIsCartModal] = React.useState(false);
  const [infoNotifications, setInfoNotifications] = React.useState([]);
  const [activeNotifications, setActiveNotifications] = React.useState(false);
  const [infoNotificationsNew, setInfoNotificationsNew] = React.useState({});

  const userInfoRef = useRef(null);
  const token = localStorage.getItem("token");

  async function fetchUserRating() {
    try {
      const { data } = await axios.get(`${apiUrl}/api/v2/users/user`, {
        headers: {
          Authorization: `Bearer ` + token,
        },
      });

      setCurrentUser(data);
    } catch (err) {
      console.log("Error:", err);
    }
  }

  async function fetchNotificationIsNew() {
    try {
      const data = await userService.getNotificationIsNew();
      setInfoNotificationsNew(Boolean(data));
    } catch (err) {
      console.log("Error:", err);
    }
  }

  async function fetchNotifications() {
    try {
      const { data } = await axios.get(`${apiUrl}/api/v2/notifications`, {
        headers: {
          Authorization: `Bearer ` + token,
        },
      });
      setInfoNotifications(data);
    } catch (err) {
      console.log("Error:", err);
    }
  }

  const onActiveNotifications = () => {
    setIsModalOpen(!isModalOpen);

    fetchNotifications();
    fetchNotificationIsNew();
    setInfoNotificationsNew(infoNotificationsNew);

    setActiveNotifications(!activeNotifications);
  };

  React.useEffect(() => {
    fetchUserRating();
    fetchNotificationIsNew();
  }, []);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (userInfoRef.current && !userInfoRef.current.contains(event.target)) {
        setActiveNotifications(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };

    // https://usehooks.com/useclickaway
    // https://usehooks-ts.com/react-hook/use-on-click-outside
  }, []);

  function getUserName(name) {
    let str = "";
    if (name) {
      const [firstName, lastName] = name.split(" ");
      str = `${
        firstName.slice(0, 1).toUpperCase() + firstName.slice(1)
      } ${lastName.slice(0, 1).toUpperCase()}.`;
    }

    return str;
  }

  const isAdmin = currentUser.role?.nameRu !== "Админ";
  const isTeacher = currentUser.role?.nameRu !== "Учитель";
  const isStudent = currentUser.role?.nameRu === "Студент";

  return (
    <div className="user-info" ref={userInfoRef}>
      {isAdmin && isTeacher && (
        <>
          <div className="hackcoins">
            <img src={hackcoins} alt="hackcoins" />
            <span>{currentUser.hakcoins}</span>
          </div>

          <div onClick={onActiveNotifications} className="alert">
            <img
              src={infoNotificationsNew === true ? bellDot : notifications}
              alt="bellDot"
            />
          </div>
        </>
      )}

      <p className="user-name">
        <span className="name">{getUserName(currentUser.name)}</span>
        <span className="rank">{currentUser.role?.nameRu}</span>
      </p>

      <Avatar
        imageSrc={
          currentUser.image_path
            ? `https://school.code06.ru/storage/app/public/${currentUser.image_path}`
            : ""
        }
        width2
      >
        {!currentUser.image_path && (
          <p style={{ fontSize: "20px" }}>
            {currentUser.name?.split(" ")[1].slice(0, 1)}
          </p>
        )}

        {isStudent && (
          <span className="user-level_count">{currentUser.level}</span>
        )}
      </Avatar>

      {activeNotifications ? (
        <Notification>
          <div className="header-notifications">
            <h2>Уведомления</h2>
            <div className="settings-notifications">
              <img src={refresh} onClick={fetchNotifications} />
            </div>
          </div>

          <div className="messages-notifications">
            {infoNotifications.length ? (
              infoNotifications.map((item) => (
                <Fragment key={item.id}>
                  <div
                    className={`messages-notification ${item.theme}_${
                      item.value > 0 ? "up" : "down"
                    }`}
                  >
                    <div className="messages-notification_image">
                      <span className="count">
                        {item.value}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_4779_4293)">
                            <path
                              d="M14.166 11.5839V5.91604C14.1658 5.66756 14.1015 5.42352 13.9796 5.20839C13.8577 4.99326 13.6825 4.81462 13.4716 4.69038L8.61046 1.85646C8.39932 1.7321 8.15982 1.66663 7.91602 1.66663C7.67221 1.66663 7.43271 1.7321 7.22157 1.85646L2.36046 4.69038C2.14953 4.81462 1.97434 4.99326 1.85245 5.20839C1.73056 5.42352 1.66627 5.66756 1.66602 5.91604V11.5839C1.66627 11.8324 1.73056 12.0764 1.85245 12.2915C1.97434 12.5067 2.14953 12.6853 2.36046 12.8095L7.22157 15.6435C7.43271 15.7678 7.67221 15.8333 7.91602 15.8333C8.15982 15.8333 8.39932 15.7678 8.61046 15.6435L13.4716 12.8095C13.6825 12.6853 13.8577 12.5067 13.9796 12.2915C14.1015 12.0764 14.1658 11.8324 14.166 11.5839Z"
                              stroke={item.value > 0 ? "#52C41A" : "red"}
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M16.68 6.63159L17.6385 7.19037C17.8494 7.31461 18.0246 7.49325 18.1465 7.70838C18.2684 7.92351 18.3327 8.16755 18.3329 8.41604V14.0839C18.3327 14.3323 18.2684 14.5764 18.1465 14.7915C18.0246 15.0066 17.8494 15.1853 17.6385 15.3095L12.7774 18.1434C12.5662 18.2678 12.3267 18.3333 12.0829 18.3333C11.8391 18.3333 11.5996 18.2678 11.3885 18.1434L10.3926 17.5629"
                              stroke={item.value > 0 ? "#52C41A" : "red"}
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5.83398 6.66663L10.0007 10.8333"
                              stroke={item.value > 0 ? "#52C41A" : "red"}
                              stroke-width="2"
                              stroke-linecap="round"
                            />
                            <path
                              d="M10 6.66663L5.83333 10.8333"
                              stroke={item.value > 0 ? "#52C41A" : "red"}
                              stroke-width="2"
                              stroke-linecap="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_4779_4293">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                    </div>

                    <div className="messages-notification_text">
                      <div className="message">{item.message}</div>
                      <div className="time">{item.time}</div>
                    </div>
                  </div>
                </Fragment>
              ))
            ) : (
              <>
                <img src={notNotification} className="noNotification"></img>
              </>
            )}
          </div>
        </Notification>
      ) : (
        isCartModal && <CardModal />
      )}
    </div>
  );
};

export default UserInfo;
