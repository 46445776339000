import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { apiUrl } from "../api/ApiService";

export const fetchProductCards = createAsyncThunk(
  "shop/fetchProductCards",
  async (categoriesId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${apiUrl}/api/v2/store/${categoriesId}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
const token = localStorage.getItem("token");

export const fetchCategoriesLists = createAsyncThunk(
  "shop/fetchCategoriesLists",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${apiUrl}/api/v2/store/categories`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const shopSlice = createSlice({
  name: "shop",
  initialState: {
    productCards: [],
    categoriesLists: [],
    categoriesId: 0,
    activeButton: 0,
    isLoading: false,
    error: null,
  },
  reducers: {
    setActiveButton(state, action) {
      state.activeButton = action.payload;
    },
    setCategoriesId(state, action) {
      state.categoriesId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductCards.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchProductCards.fulfilled, (state, action) => {
        state.isLoading = false;
        state.productCards = action.payload;
      })
      .addCase(fetchProductCards.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchCategoriesLists.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCategoriesLists.fulfilled, (state, action) => {
        state.isLoading = false;
        state.categoriesLists = action.payload;
      })
      .addCase(fetchCategoriesLists.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { setActiveButton, setCategoriesId } = shopSlice.actions;

export default shopSlice.reducer;
