import React from "react";
import "./ModalCard.scss";

import axios from "axios";
import { apiUrl } from "../../api/ApiService";
import edit from "../../assets/img/icons/pen-square.svg";
import cart from "../../assets/img/icons/cart.svg";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import ReactSelect from "../../UI/ReactSelect/ReactSelect";

import { getModulesList } from "../../redux/module/module.slice";

const ModalCard = () => {
  const [courses, setCourses] = React.useState([]);
  const [moduleCard, setModuleCard] = React.useState([]);
  const [selectedGroup, setSelectedGroup] = React.useState({});
  const [selectedCourses, setSelectedCourses] = React.useState(null);
  const { id } = useParams();
  const dispatch = useDispatch();

  const onRemove = (moduleId) => {
    const exactly = window.confirm("Вы уверены, что хотите удалить?");
    if (exactly) {
      setModuleCard(moduleCard.filter((course) => course.id !== moduleId));
    }
  };

  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  React.useEffect(() => {
    axios
      .get(`${apiUrl}/api/v2/courses/course/${id}`, {
        headers: {
          Authorization: `Bearer ` + token,
        },
      })
      .then((d) => {
        let obj = {};
        obj = {
          label: d.data.full_name,
          id: d.data.id,
          isOpen: false,
        };
        setSelectedGroup(obj);
      });
  }, []);

  React.useEffect(() => {
    const fetchProgress = async () => {
      try {
        const { data } = await axios.get(
          `${apiUrl}/api/v2/modules/${selectedCourses}`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        dispatch(getModulesList({ id }));
        dispatch(setModuleCard(data));
      } catch (error) {}
    };
    if (!selectedCourses) {
      setSelectedCourses(id);
      fetchProgress();
    } else {
      fetchProgress();
    }
  }, [id, selectedCourses]);

  React.useEffect(() => {
    async function fetchCourses() {
      try {
        const { data } = await axios.get(`${apiUrl}/api/v2/courses`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        setCourses(data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchCourses();
  }, []);

  return (
    <div
      onClick={(e) => {
        if (!(e.target.className == "react-select modale-select")) {
          const selectOptions = document.querySelector(".select-options");
          const reactSelectImg = document.querySelector(".react-select_img");
          if (selectOptions) {
            selectOptions.style.display = "none";
          }
          if (reactSelectImg) {
            reactSelectImg.classList.remove("active");
          }
        }
      }}
      className="module-card-container"
    >
      <div className="select-module">
        <ReactSelect
          className={"modale-select"}
          options={courses}
          value={selectedGroup}
          changeFunction={(option) => {
            setSelectedGroup(option);
            setSelectedCourses(option.id);

            const selectedCourseId = option.value;
            const filteredModuleCard = moduleCard.filter(
              (course) => course.courseId === selectedCourseId
            );
            setModuleCard(filteredModuleCard);
          }}
        >
          Курс : {selectedGroup.label}
        </ReactSelect>
      </div>
      <div className="flex-modalCard">
        {moduleCard.map((course) => (
          <div
            className="modalCards"
            style={{
              backgroundColor: "white",
              marginLeft: "10px",
            }}
            key={course.id}
          >
            <Link
              to={`/find-references/${course.id}`}
              style={{ textDecoration: "none", color: "black" }}
            >
              <div className="course-card-details">
                <span>
                  <h3 className="course-card-title">Модуль: {course.order}</h3>
                  <p className="description-module">{course.title}</p>
                </span>
              </div>
              <div className="info-courses">
                <p className="info-text">
                  Темы:{" "}
                  <span className="info-value">{course.theme_quantity}</span>
                </p>
                <p className="info-text">
                  Задачи:{" "}
                  <span className="info-value">{course.task_quantity}</span>
                </p>
              </div>
            </Link>

            <hr className="hr-line" />
            {role === "admin" && (
              <div className="icon-change">
                <span>
                  <Link to={`/find-references`}>
                    <img src={edit} className="icon" alt="Edit Icon" />
                  </Link>
                </span>
                <img
                  onClick={() => onRemove(course.id)}
                  src={cart}
                  className="icon"
                  alt="Cart Icon"
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ModalCard;
