import React from "react";
import "./settings.scss";
import axios from "axios";
import { apiUrl } from "../../api/ApiService";

import Switch from "../../UI/Switch/Switch";
import hideEye from "../../assets/img/icons/hideEye.svg";
import eye from "../../assets/img/icons/eye 2.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import Avatar from "../../components/Avatar/Avatar";
import { message } from "antd";

const token = localStorage.getItem("token");

const Settings = () => {
  const [avatarUrl, setAvatarUrl] = React.useState(null);
  const formRef = React.useRef(null);

  const [notifyGrades, setNotifyGrades] = React.useState(false);
  const [notifyVisits, setNotifyVisits] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState({});
  const [changePassword, setChangePassword] = React.useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [password, setPassword] = React.useState({
    oldPassword: "",
    newPassword: "",
    confirmation: "",
  });
  const [showPassword, setShowPassword] = React.useState({
    oldPassword: false,
    password: false,
    passwordConfirmation: false,
  });

  React.useEffect(() => {
    if (id) {
      axios
        .get(`${apiUrl}/api/v2/users/user/${id}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((d) => {
          setUserInfo(d.data);
        });
    } else {
      axios
        .get(`${apiUrl}/api/v2/users/user`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((d) => {
          setUserInfo(d.data);
        });
    }
  }, [id]);

  function changeAvatarAdd(e) {
    const file = e.target.files[0];
    if (file) {
      setAvatarUrl(URL.createObjectURL(file));
    }
  }
  function changeAvatarRemove() {
    setAvatarUrl(null);
    setUserInfo({ ...userInfo, image_path: "" });
    message.success("Фотография успешно удалена");
  }
  function showPasswordFunction(typePassword) {
    if (typePassword === "oldPassword") {
      setShowPassword({
        ...showPassword,
        oldPassword: !showPassword.oldPassword,
      });
    } else if (typePassword === "password") {
      setShowPassword({ ...showPassword, password: !showPassword.password });
    } else if (typePassword === "passwordConfirmation") {
      setShowPassword({
        ...showPassword,
        passwordConfirmation: !showPassword.passwordConfirmation,
      });
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();
    setTimeout(() => {
      const form = new FormData(formRef.current);

      axios
        .post(`${apiUrl}/api/v2/users${id ? `/${id}` : ""}?_method=put`, form, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          console.log("Настройки изменены");
          console.log("Settings updated:", response.data);
          message.success("Данные успешно сохранены");
        })
        .catch((error) => {
          console.error("Error updating settings:", error);
          const errorMessage =
            error.response?.data?.message || "Не удалось сохранить данные.";
          message.error(errorMessage);
        });
    }, 100);
  };

  return (
    <div className="settings">
      <form onSubmit={(e) => onSubmit(e)} ref={formRef}>
        <div className="settings_table">
          <h2>Общее</h2>
          <div className="upload">
            <div className="block_photo">
              <label htmlFor="avatar_avatarInput">
                {avatarUrl ? (
                  <Avatar width3>
                    <img className="img-upload" src={avatarUrl} alt="Avatar" />
                  </Avatar>
                ) : (
                  <Avatar width3>
                    {userInfo.image_path ? (
                      <img
                        className="img-upload"
                        src={`https://school.code06.ru/storage/app/public/${userInfo.image_path}`}
                      />
                    ) : (
                      <h1>{userInfo.name?.split(" ")[1].slice(0, 1)}</h1>
                    )}
                  </Avatar>
                )}
              </label>
              <input
                type="file"
                onChange={(e) => changeAvatarAdd(e)}
                name="image"
                id="avatar_avatarInput"
                style={{ display: "none" }}
              />
            </div>
            <label htmlFor="avatar_avatarInput" className="download">
              Загрузить фото
            </label>
            <button onClick={() => changeAvatarRemove()} className="remove">
              Удалить
            </button>
          </div>
          <div className="parts">
            <div className="part">
              <label htmlFor="first_name">Фамилия</label>
              <br />
              <input
                className="input"
                name="first_name"
                type="text"
                placeholder="Имя"
                id="first_name"
                defaultValue={userInfo.name && userInfo.name.split(" ")[0]}
              />
            </div>
            <div className="part">
              <label htmlFor="lastName">Имя</label>
              <br />
              <input
                className="input"
                name="last_name"
                type="text"
                placeholder="Фамилия"
                id="last_name"
                defaultValue={userInfo.name && userInfo.name.split(" ")[1]}
              />
            </div>
            <div className="part">
              <label htmlFor="lastName">Отчество</label>
              <br />
              <input
                className="input"
                name="patronymic"
                type="text"
                placeholder="Отчество"
                id="patronymic"
                defaultValue={userInfo.name && userInfo.name.split(" ")[2]}
              />
            </div>
          </div>
          <div className="textarea">
            <label htmlFor="about">О себе</label>
            <textarea
              style={{ fontFamily: "Nunito Sans" }}
              id="about"
              name="description"
              placeholder="Текст"
              defaultValue={userInfo.description && userInfo.description}
            ></textarea>
          </div>

          <div className="contact">
            <h2>Контакты и безопасность</h2>
            <div className="password">
              <div style={{ width: "100%", position: "relative" }}>
                <label htmlFor="old_password">Старый пароль</label>
                <br />
                <input
                  onChange={(e) =>
                    setPassword({ ...password, oldPassword: e.target.value })
                  }
                  className="input"
                  type={showPassword.oldPassword ? "text" : "password"}
                  placeholder="Старый пароль"
                  name="old_password"
                  disabled={changePassword ? true : false}
                  id="old_password"
                  value={password.oldPassword}
                />
                <img
                  onClick={() => showPasswordFunction("oldPassword")}
                  className="icon eye"
                  src={showPassword.oldPassword ? hideEye : eye}
                  alt="eye"
                />
              </div>

              <div style={{ width: "100%", position: "relative" }}>
                <label htmlFor="new_password">Новый пароль</label>
                <br />
                <input
                  className="input"
                  type={showPassword.password ? "text" : "password"}
                  placeholder="Новый пароль"
                  disabled={changePassword ? true : false}
                  id="new_password"
                  name="password"
                />
                <img
                  onClick={() => showPasswordFunction("password")}
                  className="icon eye"
                  src={showPassword.password ? hideEye : eye}
                  alt="eye"
                />
              </div>

              <div style={{ width: "100%", position: "relative" }}>
                <label htmlFor="password_confirmation">
                  Подтверждение пароля
                </label>
                <br />
                <input
                  className="input"
                  type={showPassword.passwordConfirmation ? "text" : "password"}
                  disabled={changePassword ? true : false}
                  placeholder="Подтверждение"
                  id="password_confirmation"
                  name="password_confirmation"
                />
                <img
                  onClick={() => showPasswordFunction("passwordConfirmation")}
                  className="icon eye"
                  src={showPassword.password ? hideEye : eye}
                  alt="eye"
                />
              </div>
            </div>

            <div className="number_email">
              <div style={{ width: "100%" }}>
                <label htmlFor="phone_number">Номер</label>
                <br />
                <input
                  className="input"
                  type="text"
                  name="phone_number"
                  placeholder="+7 (928) 321 22 22"
                  id="phone_number"
                  defaultValue={userInfo.phone_number && userInfo.phone_number}
                />
              </div>

              <div style={{ width: "100%" }}>
                <label htmlFor="email">Почта</label>
                <br />
                <input
                  className="input"
                  name="email"
                  type="text"
                  placeholder="ya@mail.ru"
                  id="email"
                  defaultValue={userInfo.email && userInfo.email}
                />
              </div>
            </div>
          </div>

          <div>
            <div className="settings-notification">
              {userInfo.role === "ученик" && (
                <>
                  <p>
                    <h2>Уведомления</h2>
                    Уведомление за оценки
                    <Switch
                      onChange={(e) => setNotifyGrades(!notifyGrades)}
                      value={notifyGrades ? 1 : 0}
                      name="notify_grades"
                    />
                  </p>
                  <p>
                    Уведомлять за посещения
                    <Switch
                      onChange={(e) => setNotifyVisits(!notifyVisits)}
                      value={notifyVisits ? 1 : 0}
                      name="notify_visits"
                    />
                  </p>
                </>
              )}
            </div>
            <div style={{ display: "flex" }}>
              <button className="save">Cохранить</button>
              <Link style={{ textDecoration: "none" }} to="/">
                <button className="cancel">Отмена</button>
              </Link>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Settings;
