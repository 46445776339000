import Header from "@editorjs/header";
import List from "@editorjs/list";
import ImageTool from "@editorjs/image";
import RawTool from "@editorjs/raw";

export const configurationEditor = ({ onChange, data }) => {
  return {
    holder: "editorjs",
    placeholder: "Введите заголовок",

    tools: {
      raw: RawTool,
      header: {
        class: Header,
        inlineToolbar: true,
      },
      list: {
        class: List,
        inlineToolbar: ["link", "bold"],
      },
      image: {
        class: ImageTool,
        config: {
          endpoints: {
            byFile: "/api/v2/tasks/uploadFile",
          },
        },
      },
    },
    onChange,

    data: data || {
      blocks: [
        {
          type: "header",
          data: {
            text: "Заголовок",
            level: 1,
          },
        },
      ],
    },

    autofocus: true,
  };
};

function getHeadlevel(data) {
  switch (data.level) {
    case 1:
      return <h1>{data.text}</h1>;
    case 3:
      return <h3>{data.text}</h3>;
    case 4:
      return <h4>{data.text}</h4>;
    case 5:
      return <h5>{data.text}</h5>;
    case 6:
      return <h6>{data.text}</h6>;
    default:
      return <h2>{data.text}</h2>;
  }
}

export function convertDataToJSX(block = {}) {
  if (block === undefined) return;

  switch (block.type) {
    case "header":
      return getHeadlevel(block.data);
    case "embded":
      return (
        <div>
          <iframe
            width="560"
            height="315"
            title={block.data.embed}
            src={block.data.embed}
            frameborder="0"
            allow="autoplay; encrypted-media"
            allowfullscreen
          ></iframe>
        </div>
      );

    case "paragraph":
      return <p dangerouslySetInnerHTML={{ __html: block.data.text }} />;

    case "delimiter":
      return <hr />;
    case "image":
      return (
        <div className="div-img-configuration">
          <img
            class="img-fluid"
            src={block.data.file.url}
            title={block.data.caption}
            alt=""
          />
          <br />
          <em>{block.data.caption}</em>
        </div>
      );
    case "list":
      return (
        <ul>
          {block.data.items?.items?.map(function (li) {
            return <li key={li}>${li}</li>;
          })}
        </ul>
      );
    case "table":
      return (
        <table>
          {block.data.content.map((trItem, trIdx) => (
            <tr key={trIdx}>
              {trItem.map((tdItem, tdIdx) => (
                <td key={trIdx + tdIdx}>{tdItem}</td>
              ))}
            </tr>
          ))}
        </table>
      );
    default:
      break;
  }
}
