import AddCourses from "../pages/AddCourses/AddCourses";
import Authorization from "../pages/Authorization/Authorization";
import Courses from "../pages/Courses/Courses";
import FindReferences from "../pages/FindReferences/FindReferences";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import MyPage from "../pages/MyPage/MyPage";
import Sent from "../pages/Sent/Sent";
import SiteMap from "../pages/SiteMap/SiteMap";
import GeneralMagazineAttendance from "../pages/GeneralMagazineAttendance/GeneralMagazineAttendance";
import RatingTable from "../pages/RatingTables/RatingTable";
import Settings from "../pages/Settings/Settings";

import Shop from "../pages/Shop/Shop";
import CheckTask from "../pages/GeneralJounal/CheckTask";
import SchoolChat from "../pages/SchoolChat/SchoolChat";
import FAQ from "../pages/FAQ/FAQ";
import AllCourses from "../pages/AllCourses/AllCourses";
import ModalCard from "../components/ModalCard/ModalCard";
import UserAdmin from "../pages/UserAdmin/UserAdmin";
import AddNewUser from "../pages/AddNewUser/AddNewUser";
import AdminGroups from "../pages/AdminGroups/AdminGroups";
import OutUserGroup from "../pages/OutUserGroup/OutUserGroup";
import ResetPassword from ".././pages/ResetPassword/ResetPassword";

export const authRoutes = [
  { path: "/", element: <Authorization /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
  { path: "/sent/:email", element: <Sent /> },
  { path: "/resetPassword/:token", element: <ResetPassword /> },
];

export const routesUsers = [
  { path: "/", element: <MyPage /> },
  { path: `/courses/:id`, element: <Courses /> },
  { path: `/magazine`, element: <CheckTask /> },
  { path: "/site-map/:id", element: <SiteMap /> },
  { path: "/shop", element: <Shop /> },
  { path: "/settings", element: <Settings /> },
  { path: "/faq", element: <FAQ /> },
  { path: "/ranking-table", element: <RatingTable /> },
  { path: "/chat", element: <SchoolChat /> },
  { path: "/add-user", element: <AddNewUser /> },
];

export const routesAdmins = [
  { path: "/", element: <AllCourses /> },
  { path: `/courses/:id`, element: <Courses /> },
  { path: "/settings", element: <Settings /> },
  { path: "/settings/:id", element: <Settings /> },
  { path: "/ranking-table", element: <RatingTable /> },
  { path: "/chat", element: <SchoolChat /> },
  { path: "/faq", element: <FAQ /> },
  { path: "/find-references", element: <FindReferences /> },
  { path: "/find-references/:id", element: <FindReferences /> },
  { path: "/add-course", element: <AddCourses /> },
  { path: "/admin-groups", element: <AdminGroups /> },
  { path: "/change-course/:id", element: <AddCourses /> },
  { path: "/modules-admin/:id", element: <ModalCard /> },
  { path: "/users", element: <UserAdmin /> },
  { path: "/add-user", element: <AddNewUser /> },
  { path: "/out-user/:id", element: <OutUserGroup /> },
];
export const routesTeachers = [
  { path: "/", element: <AllCourses /> },
  { path: "/courses/:id", element: <Courses /> },
  { path: "/modules-admin/:id", element: <ModalCard /> },
  { path: "/find-references", element: <FindReferences /> },
  { path: "/find-references/:id", element: <FindReferences /> },
  { path: "/general-magazine", element: <GeneralMagazineAttendance /> },
  { path: "/magazine", element: <CheckTask /> },
  { path: "/shop", element: <Shop /> },
  { path: "/settings", element: <Settings /> },
  { path: "/ranking-table", element: <RatingTable /> },
  { path: "/chat", element: <SchoolChat /> },
  { path: "/faq", element: <FAQ /> },
];
