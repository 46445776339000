import React, { useState } from "react";
import edit from "../.././assets/img/icons/pen-square.svg";
import cart from "../../assets/img/icons/cart.svg";
import iconCourseDesign from "../.././assets/img/DesignUI.svg";
import iconCourseCode from "../.././assets/img/IconCode.svg";

import "./CourseOfCard.scss";
import axios from "axios";
import { apiUrl } from "../../api/ApiService";
import { Link, useNavigate } from "react-router-dom";

import { Modal } from "antd";

const CourseOfCard = ({ setCourses, filteredData }) => {
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const navigate = useNavigate();

  const [courseIdToDelete, setCourseIdToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const onRemoveCourse = (courseId) => {
    setCourseIdToDelete(courseId);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalConfirm = async () => {
    setIsDeleteModalOpen(false);
    try {
      const requestData = {
        id: courseIdToDelete,
      };

      await axios.post(`${apiUrl}/api/v2/courses?_method=delete`, requestData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      setCourses((prevCourses) =>
        prevCourses.filter((course) => course.id !== courseIdToDelete)
      );
      setCourseIdToDelete(null);
    } catch (error) {
      console.log("Error deleting course", error);
    }
  };

  const onChangeCourse = (courseId) => {
    setCourseIdToDelete(courseId);
    setIsEditModalOpen(true);
  };

  const handleEditModalConfirm = () => {
    setIsEditModalOpen(false);
    navigate(`/change-course/${courseIdToDelete}`);
  };

  return (
    <div className="course-card-container">
      {filteredData &&
        filteredData.map((course) => {
          const icon = course.categories.map((icons) => {
            return (
              <img
                className="icon-course"
                src={icons.id === 1 ? iconCourseDesign : iconCourseCode}
                alt="Course Icon"
              />
            );
          });
          return (
            <div
              className="courseCards"
              style={{
                backgroundColor: course.isVisible ? "white" : "#F0F0F0",
                opacity: course.isVisible ? 1 : 0.5,
              }}
              key={course.id}
            >
              <Link
                to={`/modules-admin/${course.id}`}
                style={{ textDecoration: "none", color: "black" }}
              >
                <div className="course-card-details">
                  {icon}
                  <span>
                    <h3 className="course-card-title">
                      {truncateText(course.full_name, 13)}
                    </h3>
                    <p>{truncateText(course.short_name, 17)}</p>
                  </span>
                </div>
                <div className="info-courses">
                  <p className="info-text">
                    Модули:{" "}
                    <span className="info-value">{course.module_quantity}</span>
                  </p>
                  <p className="info-text">
                    Задачи:{" "}
                    <span className="info-value">{course.task_quantity}</span>
                  </p>
                </div>
              </Link>

              <hr className="hr-line" />
              {role === "admin" && (
                <div className="icon-change">
                  {course.isVisible && (
                    <>
                      <img
                        src={edit}
                        className="icon"
                        onClick={() => onChangeCourse(course.id)}
                        alt="Edit Icon"
                      />
                      <span>
                        <img
                          src={cart}
                          className="icon"
                          onClick={() => onRemoveCourse(course.id)}
                          alt="Cart Icon"
                        />
                      </span>
                    </>
                  )}
                </div>
              )}
            </div>
          );
        })}
      <Modal
        visible={isDeleteModalOpen}
        onCancel={() => setIsDeleteModalOpen(false)}
        onOk={handleDeleteModalConfirm}
      >
        <h1>Внимание!</h1>
        <p>Вы уверены, что хотите удалить курс?</p>
      </Modal>

      <Modal
        visible={isEditModalOpen}
        onCancel={() => setIsEditModalOpen(false)}
        onOk={handleEditModalConfirm}
      >
        <h1>Внимание!</h1>
        <p>Вы уверены, что хотите изменить курс?</p>
      </Modal>
    </div>
  );
};

export default CourseOfCard;
